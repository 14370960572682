:root {
  //colors
  --main-black: #0F172A;
  --main-white: #FFFFFF;
  --main-blue: #2563EB;
  --second-blue: #2767ED;
  --main-gray: #64748B;
  --second-gray: #94A3B8;
  --third-gray: #B4BECE;

  //gradients
  --blue-text-gradient: linear-gradient(#2664EC, #268BEC);
  --blue-back-gradient:  linear-gradient(
      78.74deg,
      rgba(39, 166, 206, 0.6) 1.2844214215874672%,
      rgba(39, 115, 228, 0) 100%
  ),
  linear-gradient(
      263.64deg,
      rgba(158, 0, 255, 0.6) 0%,
      rgba(53, 121, 233, 0) 100%
  ),
  linear-gradient(
      180deg,
      rgba(38, 100, 236, 1) 0%,
      rgba(38, 139, 236, 1) 100%
  );

  //border-radius
  --br-main: 28px;
  --br-small: 12px;
}
