@import "./root", "./reset.css";

html, body{
  //width: 100vw;
  //overflow-x: hidden;
}

body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased; ///зменшує жирність на сафарі
  background-color: var(--main-white);
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  color: var(--main-black);
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: #2563EB #FAFAFA;

  &::-webkit-scrollbar-thumb:hover {
    background-color: #2563EB;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #FAFAFA;
  }

  &::-webkit-scrollbar-thumb {
    //border: 3px solid #FAFAFA;
    border-radius: 100px;
    background-color: #2563EB;
    background-clip: content-box;
    transition: all 1s ease-out;
  }
}
